import React from "react";

import { Link } from 'gatsby';

import { graphql } from 'gatsby'
import Img from 'gatsby-image';

import {FaAngleLeft} from 'react-icons/fa';

export const query = graphql`
  query {
    logo: file(relativePath: { regex: "/^logo-only-black.png$/" }) {
      childImageSharp {
        fixed(width:150, quality:90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default ({data}) => 
<div className="vh-100 d-flex flex-column justify-content-center align-items-center off-white-background">

  <Img fixed={data.logo.childImageSharp.fixed} alt="Vancouver Jade"/>
  <h2 className="text-dark sans-serif logo-font mt-n4 text-center">VANCOUVER JADE</h2>
  <div className="text-center">
    <h1 className="serif font-weight-bold text-dark mt-5 mb-4">Thank You</h1>
    <p className="sans-serif text-dark mb-0">
      We have received your enquiry.
    </p>
    <div className="mt-4">
      <Link to="/" className="default-links sans-serif text-dark pt-5"><FaAngleLeft/> Back to Site</Link>
    </div>
  </div>

</div>
